const mobileMenuBtn = document.querySelector('.header__toggle-menu');
const header = document.querySelector('.header__menu-wrapper');
const headerSticky = document.querySelector('.header--position-sticky');

if (mobileMenuBtn) {
  mobileMenuBtn.addEventListener('click', () => {
    mobileMenuBtn.classList.toggle('header__toggle-menu--active');
    header.classList.toggle('header__menu-wrapper--open');
    document.body.classList.toggle('menu--open');
    document.documentElement.classList.toggle('overflow');
  });
}

if (headerSticky) {
  window.addEventListener(
    'scroll',
    () => {
      const banner = document.querySelector('[data-notify-widget]');
      headerSticky.classList.toggle(
        'header--sticky',
        window.pageYOffset > (banner?.clientHeight || 0),
      );
    },
    false,
  );
}
