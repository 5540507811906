import { observeElement } from '@uvocore-front/utils';
/**
 *  Inline Images Observer
 */
observeElement({
  element: '[data-observed-image]',
  marginValue: 300,
  callback: (img) => {
    const src = img.getAttribute('data-src');
    const srcset = img.getAttribute('data-srcset');

    if (src) img.src = src; /* eslint-disable-line */
    if (srcset) img.srcset = srcset; /* eslint-disable-line */
  },
});

/**
 *  Background Images Observer
 */
observeElement({
  element: '[data-observed-bg]',
  marginValue: 300,
  callback: (bgImg) => bgImg.classList.add('load-bg'),
});
