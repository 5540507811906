import { observeElement } from '@uvocore-front/utils';
import '../../theme/DefaultTheme.js';
import './index.scss';

// Animation avatars on top screen
const loadAnimation = () => {
  const leftFacesNodes = document.querySelectorAll('[data-faces-animate]');

  if (!leftFacesNodes) return;

  leftFacesNodes.forEach((i) => i.classList.add('animate'));
};

loadAnimation();

